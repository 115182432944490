<template>
  <v-container>
    <v-data-table
      :headers="headers_cash"
      :items="cash_type"
      :items-per-page="10"
      item-key="id"
      sort-by="name"
      class="elevation-4"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
    >
      <template #top>
        <v-toolbar flat>
          <h1>Reporte de Caja</h1>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.valor="{ item }">
        <span>{{ parseFloat(item.valor).toLocaleString(2) }}</span>
      </template>
      <template #item.actions="{ item }">
        <v-icon class="mr-2" @click="showInvoice(item)">
          mdi-magnify
        </v-icon>
      </template>
      <template slot="body.append">
      <tr>
          <th class="title">Total</th>
          <th class="text-center title">{{ sumCash('qty') }}</th>
          <th class="text-right title">{{ sumCash('valor') }}</th> 
      </tr>
      </template>
    </v-data-table>

    <v-data-table
      :headers="headers"
      :items="cash_data"
      :items-per-page="10"
      item-key="id"
      sort-by="name"
      class="elevation-4 mt-6"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
    >
      <template #top>
        <v-toolbar flat>
          <h1>Documentos</h1>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.valor="{ item }">
        <span>{{ parseFloat(item.valor).toLocaleString(2) }}</span>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-2" @click="showInvoice(item)">
          mdi-magnify
        </v-icon>
      </template>
            <template slot="body.append">
      <tr>
          <th class="title">Total</th>
          <th></th>
          <th class="text-center title">{{ sumDocs('quantity') }}</th>
          <th class="text-right title">{{ sumDocs('descuento') }}</th> 
          <th class="text-right title">{{ sumDocs('valor') }}</th> 
      </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { webserver, getToday } from "../services/webserver.js";
export default {
  data() {
    return {
      loading_status: false,
      headers: [
        {
          text: "Documento ID",
          align: "start",
          sortable: true,
          value: "order_number",
          dataType: "text",
        },
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "client_name",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "quantity",
          dataType: "number",
        },
        {
          text: "Desceunto",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "valor",
          dataType: "number",
        },
        { text: "", value: "actions",align: "end", sortable: false },
      ],
      headers_cash: [
        {
          text: "Forma de pago",
          align: "start",
          sortable: true,
          value: "tipo",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: true,
          value: "qty",
          dataType: "number",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "valor",
          dataType: "number",
        },
      ],
      cash_data: [],
      cash_type: [],
    };
  },
  mounted() {
    this.get_report();
  },
  methods: {
    sumCash(key) {
        // sum data in give key (property)
        return parseFloat(this.cash_type.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
    },
    sumDocs(key) {
        // sum data in give key (property)
        return parseFloat(this.cash_data.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
    },
    showInvoice(e) {
      console.log(e);
    },
    get_report() {
      this.loading_status = true;

      var qry = {
        store: window.store.store_id,
        dateTo: getToday(),
        userId: window.profile.user_email,
      };
      console.log(qry);
      webserver("get_sales_2021", qry, (data) => {
        console.log(data);
        //  var result = data.reduce(function (r, a) {
        //     r[a.tipo] = r[a.tipo] || [];
        //     r[a.tipo].push(a);
        //     return r;
        // }, Object.create(null));
        // console.log(result);

        this.loading_status = false;
        this.cash_data = data.documents;
        this.cash_type = data.cash;
      });
    },
  },
};
</script>

<style></style>
